import { toast } from 'vue3-toastify'

export const notify = (text, level='info') => {
  if (level == 'error'){
    toast.error(text,{
    theme: 'dark'
    })  
  }
  else if (level == 'warning'){
    toast.warning(text,{
    theme: 'dark'
    })  
  }
  else{
    toast(text, {
      autoClose: 3000,
      theme: 'dark'
    });  
  }
}

function _f(dt) {
  if (dt < 10) {
    return "0" + dt;
  }
  return dt;
}

export const getFullFormatDateFromISOString = (isoString, time=false) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  let month = _f(date.getMonth() + 1);
  let dt = _f(date.getDate());
  let timing = _f(date.getHours()) + ':' + _f(date.getMinutes()) + ':' + _f(date.getSeconds());

  if (time) return year + "-" + month + "-" + dt + ' ' + timing;
  else return year + "-" + month + "-" + dt;
};
